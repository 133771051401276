/* eslint-disable no-console */
import { openDB } from 'idb'

const STORAGE = 'MOR_STORAGE'
const STORAGE_VERSION = 1

export const INFINITE_LISTS_STORE = 'INFINITE_LISTS_STORE'

const getStorageDB = async () => {
  const idb = await openDB<any>(STORAGE, STORAGE_VERSION, {
    upgrade(db: any) {
      db.createObjectStore(INFINITE_LISTS_STORE, { keyPath: 'path' })
    },
  })
  return idb as any
}

const putIdbEntry = async (objectStore: string, entry: any) => {
  const db = await getStorageDB()

  if (db) {
    try {
      const tx = db?.transaction([objectStore], 'readwrite')
      tx.store.put(entry)
    } catch (e) {
      console.error('IndexedDB: Unable to put entry:', e.toString())
    }
  }
}

const deleteIdbEntry = async (objectStore: string, index: string) => {
  const db = await getStorageDB()

  if (db) {
    try {
      const tx = db?.transaction([objectStore], 'readwrite')
      tx.store.delete(index)
    } catch (e) {
      console.error('IndexedDB: Unable to delete entry')
    }
  }
}

const getIdbEntry = async (objectStore: string, index: string) => {
  const db = await getStorageDB()

  if (db) {
    try {
      const tx = db?.transaction([objectStore], 'readwrite')
      return await tx.store.get(index)
    } catch (e) {
      console.error(e.toString())
      return null
    }
  }
  return null
}

export { putIdbEntry, deleteIdbEntry, getIdbEntry }
