/**
 * Creates a custom useEffect() hook that only fires when the values in the dependency list change
 * not when the component first mounts
 * @param effectFunction Function containing the logic that the hook will run
 * @param deps Dependency list for the useEffect() hook
 */
import { useEffect, useRef } from 'react'

function useUpdateOnlyEffect<T>(effectFunction: () => void, deps: T[]) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      effectFunction()
    } else {
      didMountRef.current = true
    }
  }, deps)
}

export default useUpdateOnlyEffect
