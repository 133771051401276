import * as React from 'react'
import delay from '../../../utils/Delay'
import css from './Loader.scss'
import PleaseWait from './PleaseWait'
import { withContextRoot } from '../../../utils'

const spinners = [
  'gears',
  'gear',
  'spinner',
  'ripple',
  '3gears',
  'ring',
  'ripple',
  'spiral',
  'square',
  'wedges',
  'cart',
]
const getRandomSpinner = (): string => {
  return spinners[Math.floor(spinners.length * Math.random())]
}
interface IProps {
  center?: boolean
  color?: string
  border?: boolean
  spinner?: string
  bold?: boolean
  svg?: boolean
  timeout?: number
  randomSpinner?: boolean
  children?: any
}
/**
 * Simple loader
 * @param spinner: 'gears', 'gear', 'spinner', 'ripple', '3gears', 'ring', 'ripple', 'spiral', 'square', 'wedges', 'cart'
 * @param svg: boolean do you want to use svg or gif, IE doesn't fully support animated svgs
 * @param randomSpinner: boolean allow random spinner or default to spinner
 * @param timeout number: set a timeout in ms for when the components changes to the timeout state (TODO pass in timeout component for custom renders)
 * @param center boolean: whether to center the content or leave left aligned, default to false
 * @param border boolean: add a border, default to false
 */
export default class Loader extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
    this.state = { timeout: false }
  }
  // Catch errors in any components below and re-render with error message
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    })
  }
  makeApiCall = async (): Promise<void> => {
    const { timeout } = this.props
    if (timeout !== undefined) {
      await delay(timeout)
      this.setState({ timeout: true })
    }
  }
  ComponentDidMount() {
    this.makeApiCall()
  }
  generateClasses = (): string => {
    const { border } = this.props
    if (border) {
      return css.border
    }
    return ''
  }
  renderTextBody = (): JSX.Element => {
    const { children } = this.props
    const showDefault = children === undefined
    if (showDefault) {
      return <PleaseWait />
    }
    return children
  }
  renderLoadingText = (): JSX.Element => {
    const { bold, color } = this.props
    if (bold) {
      return (
        <span className={css.marginLeft}>
          <h3 className={css.inline} style={{ color: color ? color : 'black' }}>
            {this.renderTextBody()}
          </h3>
        </span>
      )
    }
    return (
      <span className={css.marginLeft}>
        <p className={`${css.inline} ${css.messageText}`} style={{ color: color ? color : 'black' }}>
          {this.renderTextBody()}
        </p>
      </span>
    )
  }
  renderSpinner = (): JSX.Element => {
    const { spinner, randomSpinner, svg } = this.props
    let targetSpinner = spinner || 'ripple'
    // set default to false
    const isRandomSpinner = randomSpinner === undefined && spinner === undefined ? false : randomSpinner
    // next let's determine if we want to use a gif or svg. IE doesn't support animated svg very well
    // TODO: switch out based on browser?
    const isSvg = svg === undefined ? true : svg
    if (isRandomSpinner) {
      targetSpinner = getRandomSpinner()
    }
    return <img src={withContextRoot(`/static/spinners/${targetSpinner}.${isSvg ? 'svg' : 'gif'}`)} alt="loading" />
  }
  renderBody = (): JSX.Element => {
    const { timeout } = this.state
    // first determine if there is a timeout
    if (timeout) {
      return <p className="fadeIn">This component is taking longer than expected to load...</p>
    }
    // if no timeout, render our spinner
    const { center } = this.props
    return (
      <div className={`${center ? css.center : ''} ${css.flexCenter}`}>
        {this.renderSpinner()}
        {this.renderLoadingText()}
      </div>
    )
  }
  public render(): JSX.Element {
    const { errorInfo } = this.state
    if (errorInfo) {
      return <div>Error loading...</div>
    }
    return <div className={this.generateClasses()}>{this.renderBody()}</div>
  }
}
