import React from 'react'
import { deleteIdbEntry, getIdbEntry, INFINITE_LISTS_STORE, putIdbEntry } from '../../services/storage/IndexedDB'
import { IFacetFilter, ISearch } from '../../utils/unbxd/unbxdHelpers'
import {
  IUnbxdProduct,
  IUnbxdSearchQueryResponse,
} from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { IProduct } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { ICatalogNavigationItem } from '../Tenant/Layout/Catalog/Navigation/Navigation'

interface IBrowsePageRestorationDeps {
  products: IProduct[]
  facets: ICatalogNavigationItem[]
  results: IUnbxdSearchQueryResponse | null
  setProducts: React.Dispatch<React.SetStateAction<IUnbxdProduct[]>>
  setFacets: React.Dispatch<React.SetStateAction<ICatalogNavigationItem[]>>
  setResults: React.Dispatch<React.SetStateAction<IUnbxdSearchQueryResponse | null>>
  search: ISearch
  setSearch: React.Dispatch<React.SetStateAction<ISearch>>
  query: any
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedFacets?: React.Dispatch<React.SetStateAction<IFacetFilter[]>>
}

type IBrowsePageRestorationCallback = () => void

export const BROWSE_PAGE_SESSION_PAGE = 'BROWSE_PAGE_SESSION_PAGE'
export const BROWSE_PAGE_SESSION_SCROLL = 'BROWSE_PAGE_SESSION_SCROLL'
export const BROWSE_PAGE_IS_NEW_SESSION = 'BROWSE_PAGE_IS_NEW_SESSION'

const onPersistBrowsePage = (
  products: IProduct[],
  facets: ICatalogNavigationItem[],
  results: IUnbxdSearchQueryResponse | null,
  search: ISearch,
) => {
  if (history?.state) {
    try {
      sessionStorage.setItem(BROWSE_PAGE_SESSION_PAGE, history.state.url)
      sessionStorage.setItem(BROWSE_PAGE_SESSION_SCROLL, String(window.scrollY))
      putIdbEntry(INFINITE_LISTS_STORE, {
        path: history.state.url,
        scroll: String(window.scrollY),
        data: {
          products: products,
          facets: facets,
          search: search,
          results: {
            response: {
              numberOfProducts: results?.response?.numberOfProducts,
              start: results?.response?.start,
            },
            searchMetaData: {
              queryParams: results?.searchMetaData?.queryParams,
            },
            didYouMean: results?.didYouMean,
          },
        },
      })
    } catch (e) {
      // most likely quota exceeded when trying to save a very large dataset
      const errorMessage: string = e.messages || 'Unknown error'
      console.error('Infinite list indexedDb error: ', errorMessage)
    }
  }
}

const useBrowsePageRestoration = (
  callOtherwise: IBrowsePageRestorationCallback,
  {
    products,
    facets,
    results,
    setProducts,
    setFacets,
    setResults,
    query,
    search,
    setSearch,
    setLoading,
    setSelectedFacets,
  }: IBrowsePageRestorationDeps,
) => {
  const onPageLeave = React.useCallback(() => {
    const isNewSearch = sessionStorage.getItem(BROWSE_PAGE_IS_NEW_SESSION)
    if (isNewSearch === 'true') {
      deleteIdbEntry(INFINITE_LISTS_STORE, history.state.url)
      sessionStorage.removeItem(BROWSE_PAGE_SESSION_PAGE)
      sessionStorage.removeItem(BROWSE_PAGE_SESSION_SCROLL)
      sessionStorage.removeItem(BROWSE_PAGE_IS_NEW_SESSION)
    } else {
      onPersistBrowsePage(products, facets, results, search)
    }

    return undefined
  }, [products, facets, results, search])

  React.useEffect(() => {
    // Mobile view on browser not working as expected on IOS. Workaround for this is not to delete the store of data on leaving page.
    const isIos =
      (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || /Version\/13/.test(navigator.userAgent)
    const eventName = isIos ? 'pagehide' : 'beforeunload'
    window.addEventListener(eventName, onPageLeave)
    return () => window.removeEventListener(eventName, onPageLeave)
  }, [onPageLeave])

  React.useEffect(() => {
    const sessionPage = sessionStorage.getItem(BROWSE_PAGE_SESSION_PAGE) || 'INVALID'
    const sessionScroll = parseInt(sessionStorage.getItem(BROWSE_PAGE_SESSION_SCROLL) || '0')

    const preloadOrInit = async () => {
      let shouldCallOtherwise = true

      if (sessionPage && history?.state?.url.indexOf(sessionPage) !== -1) {
        const storedData = await getIdbEntry(INFINITE_LISTS_STORE, history.state.url)
        if (storedData) {
          const parsedData = storedData.data

          setProducts([...parsedData.products])
          setFacets([...parsedData.facets])
          setResults({ ...parsedData.results })
          setSearch(parsedData.search)
          if (setSelectedFacets) {
            setSelectedFacets(parsedData.search.filter)
          }
          setLoading(false)

          window.scrollTo(0, sessionScroll)

          shouldCallOtherwise = false
        }
      }

      if (shouldCallOtherwise) {
        callOtherwise()
      }
    }

    try {
      preloadOrInit()
    } catch {
      callOtherwise()
    }
  }, [query])

  return [onPageLeave]
}

export { useBrowsePageRestoration, onPersistBrowsePage }
